<template>
    <Modal v-model="showForm" title="编辑组织分类" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="90">
            <FormItem label="组织编号" prop="code">
                <Input v-model.trim="editForm.code" :maxlength="30" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="组织名称" prop="name">
                <Input v-model.trim="editForm.name" :maxlength="30" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="组织电话" prop="telephone">
                <Input v-model.trim="editForm.telephone" :maxlength="30" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="组织负责人" prop="managerId">
                <Select v-model="editForm.managerId" label-in-value @on-change="handleManagerChange" placeholder="请选择" filterable clearable>
                    <Option v-for="item in managerNameList" :value="item.id" :key="item.id"
                    >{{ item.name }}
                    </Option>
                </Select>
            </FormItem>
            <FormItem label="组织简介" prop="introduction">
                <Input v-model="editForm.introduction" maxlength="100" :autosize="{minRows: 2,maxRows: 5}" show-word-limit type="textarea" />
            </FormItem>
            <FormItem label="上级组织" prop="parentId">
                <TreeSelect v-model="editForm.parentId" :options="treeSelectOpts" placeholder="请选择" noOptionsText="暂无数据"
                            noResultsText="没有匹配数据" clearable/>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {reqGetUserList} from "@/api/system-api";
import {reqEditDepartment} from "@/api/admin-api";
import {checkPhone} from "@/common/publicFuns";
import {reqGetAvailParentDeptOpts} from "../../../api/admin-api";

export default {
    name: "editDepartment",
    components: {
        TreeSelect
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        data: {}
    },
    data() {
        return {
            managerNameList: [],
            showForm: this.value,
            loading: false,
            treeSelectOpts: [],
            editForm: {
                name: '',
                code: '',
                telephone: '',
                parentId: null,
                managerId: '',
            },
            editFormRule: {
                name: [
                    {required: true, message: '请输入组织名称', trigger: 'blur'}
                ],
                telephone: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !checkPhone(value)) {
                            return callback(new Error('联系电话不正确(座机格式\'区号-座机号码\')'));
                        }
                        return callback();
                    }
                }],
            }
        }
    },
    methods: {
        handleSubmit() {
            this.$refs['editForm'].validate((valid)=> {
                if (valid) {
                    this.loading = true;
                    if (this.editForm.parentId===this.editForm.id){
                        this.$Message.error("上级组织不能选择自身");
                        this.loading = false;
                        return
                    }
                    delete this.editForm['createTime'];
                    reqEditDepartment(this.editForm).then((res)=> {
                        this.loading = false;
                        if (res.data.code === 1) {
                            this.closeModal(false);
                            this.$emit('getTreeList');
                            this.$emit('getChildListById');
                            this.$Message.success('编辑成功');
                        }
                    }).catch(()=>{
                        this.loading = false;
                        this.$Message.error('编辑失败');
                    })
                }
            })
        },
        getAllUserList() {
            reqGetUserList().then((res) => {
                this.managerNameList = res.data;
            }).catch(() => {
                this.managerNameList = []
            })
        },
        handleManagerChange(value){
            if (value) {
                this.editForm.managerName = value.label;
            }else {
                this.editForm.managerName = ''
                this.editForm.managerId = ''
            }

        },
        getAvailParentDeptOpts() {
            reqGetAvailParentDeptOpts({id: this.data.id}).then((res) => {
                this.treeSelectOpts = res.data.data;
            }).catch(() => {
                this.treeSelectOpts = []
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                Object.assign(this.editForm, this.data);
                this.getAvailParentDeptOpts();
                this.getAllUserList();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
