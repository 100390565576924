<template>
    <Modal v-model="showForm" title="新增组织分类" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon :label-width="90">
            <FormItem label="组织编号" prop="code">
                <Input v-model.trim="addForm.code" :maxlength="30" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="组织名称" prop="name">
                <Input v-model.trim="addForm.name" :maxlength="30" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="组织电话" prop="telephone">
                <Input v-model.trim="addForm.telephone" :maxlength="30" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="组织负责人" prop="managerId">
                <Select v-model="addForm.managerId" label-in-value @on-change="handleManagerChange" placeholder="请选择" filterable clearable>
                    <Option v-for="item in managerNameList" :value="item.id" :key="item.id"
                    >{{ item.name }}
                    </Option>
                </Select>
            </FormItem>
            <FormItem label="组织简介" prop="introduction">
                <Input v-model.trim="addForm.introduction" maxlength="100" :autosize="{minRows: 2,maxRows: 5}" show-word-limit type="textarea" />
            </FormItem>
            <FormItem label="上级组织" prop="parentId">
                <TreeSelect v-model="addForm.parentId" :options="treeSelectOpts" placeholder="请选择" noOptionsText="暂无数据"
                            noResultsText="没有匹配数据" clearable/>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {reqGetUserList} from "@/api/system-api";
import {reqAddDepartment} from "@/api/admin-api";
import {checkPhone} from "@/common/publicFuns";

export default {
    name: "addDepartment",
    components: {
        TreeSelect
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        treeSelectOpts: [],
        pId: null
    },
    data() {
        return {
            managerNameList: [],
            showForm: this.value,
            loading: false,
            addForm: {
                name: '',
                code: '',
                telephone: '',
                parentId: null,
                managerId: '',
                managerName: '',
                introduction: '',
            },
            addFormRule: {
                name: [
                    {required: true, message: '请输入组织名称', trigger: 'blur'}
                ],
                telephone: [{trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value && !checkPhone(value)) {
                            return callback(new Error('联系电话不正确(座机格式\'区号-座机号码\')'));
                        }
                        return callback();
                    }
                }],
            }
        }
    },
    methods: {
        handleSubmit() {
            this.$refs['addForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    reqAddDepartment(this.addForm).then((res)=> {
                        this.loading = false;
                        if (res.data.code === 1) {
                            this.closeModal(false);
                            this.$emit('getTreeList');
                            this.$Message.success('添加成功');
                        }
                    }).catch(()=>{
                        this.loading = false;
                        this.$Message.error('添加失败');
                    })
                }
            });
        },
        getAllUserList() {
            reqGetUserList().then((res) => {
                this.managerNameList = res.data;
            }).catch(() => {
                this.managerNameList = [];
            })
        },
        handleManagerChange(value){
            if (value) {
                this.addForm.managerName = value.label;
            }
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['addForm'].resetFields();
                this.addForm.parentId = this.pId;
                this.getAllUserList()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
