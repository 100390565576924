<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>系统管理</BreadcrumbItem>
                <BreadcrumbItem>组织管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Input v-model="keywords" placeholder="输入组织名称、编号" clearable/>
                </FormItem>
                <FormItem>
                    <Button type="primary"  @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-add" @click="handleAddDepartment" v-if="permissionButton.add">新增</Button>
                </FormItem>
            </Form>
        </div>
        <Split class="split-pane" min="300" v-model="splitPercent">
            <div slot="left" class="split-pane-left">
                <div class="v-tree">
                    <Tree :data="treeList" :render="renderContent"></Tree>
                </div>
            </div>
            <div slot="right" class="split-pane-right">
                <div class="table">
                    <div class="table-list">
                        <Table stripe :columns="columns" :data="deportmentList" :loading="listLoading">
                            <template slot-scope="{ row, index }" slot="action">
                                <a type="text" @click="handleEditDepartment(row, index)" v-if="permissionButton.edit" ghost>编辑</a>
                                <Divider type="vertical"/>
                                <a type="error" @click="handleDelDepartment(row, index)" v-if="permissionButton.del" ghost >删除</a>
                            </template>
                        </Table>
                    </div>
                    <div class="table-footer">
                        <Row>
                            <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                            <Col class="pagenation" span="18">
                                <Page :page-size="pageSize"
                                      :total="total"
                                      :current="pageNum"
                                      :page-size-opts="[15,20,50,100]"
                                      @on-change="changePage"
                                      @on-page-size-change="changPageSize"
                                      show-sizer
                                      show-elevator/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </Split>
        <AddDepartment v-model="addShow" :treeSelectOpts="treeSelectOpts" :pId="parentId" v-on:getTreeList="getTreeList"></AddDepartment>
        <EditDepartment v-model="editShow" :data="deportment" v-on:getTreeList="getTreeList" v-on:getChildListById="getChildListById"></EditDepartment>
        </div>
</template>

<script>
import permission from '@/common/permission'
import {
    reqDelDepartment,
    reqGetChildListById,
    reqGetDepartmentByFilters,
    reqGetDepartmentTreeOpts
} from "@/api/admin-api";
import AddDepartment from './add';
import EditDepartment from './edit';

export default {
    name: 'resumeList',
    components: {
        AddDepartment,
        EditDepartment,
    },
    data() {
        return {
            keywords: '',
            editState: false, // 编辑状态
            deportment: null,
            deportmentList: [],
            listLoading: false,
            splitPercent: 0.2,
            treeSelectOpts: [],
            treeList: [],
            filter: {},
            loading: false,
            dataList: [],
            permissionButton: {
                add: false,
                edit: false,
                view: false,
                del: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            parentId: '',
            addShow: false,
            editShow: false,
            importShow: false,
            buttonProps: {
                type: 'default',
                size: 'small',
            },
            // 输入框要修改的内容
            inputContent: '',
            // 修改前的TreeNode名称
            oldName: '',
            columns: [
                {
                    title: '组织名称',
                    key: 'name',
                    width: 170,
                },
                {
                    title: '编号',
                    key: 'code',
                    width: 100,
                },
                {
                    title: '电话',
                    key: 'telephone',
                    width: 180,
                },
                {
                    title: '组织负责人',
                    key: 'managerName',
                    minWidth: 220,
                },
                {
                    title: '注册时间',
                    key: 'createTime',
                    minWidth: 180,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 130,
                    align: 'center',
                },
            ],
        }
    },
    methods: {
        doSearching() {
            this.isSearching = 1;
            this.pageNum = 1;
            this.getDeportmentByFilters();
        },
        getDeportmentByFilters() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keywords: this.keywords
            }
            reqGetDepartmentByFilters(params).then((res)=> {
                this.loading = false;
                if (res.data.code === 1) {
                    this.deportmentList = res.data.data.records;
                    this.pageNum = res.data.data.current;
                    this.pages = res.data.data.pages;
                    this.total = res.data.data.total;
                } else {
                    this.deportmentList = [];
                }
            }).catch(()=>{
                this.deportmentList = [];
            })
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            if (this.isSearching === 1) {
                this.getDeportmentByFilters();
            } else {
                this.getChildListById();
            }
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.pageNum = 1;
            if (this.isSearching === 1) {
                this.getDeportmentByFilters();
            } else {
                this.getChildListById();
            }
        },
        handleAddDepartment() {
            this.parentId = null;
            this.addShow = true;
        },
        handleEditDepartment(row, index) {
            this.deportment = row;
            this.editShow = true;
        },
        handleDelDepartment(row, index) {
            this.delDelDepartment(row.id);
        },
        delDelDepartment(deportmentId) {
            this.$Modal.confirm({
                title: ' ',
                content: '确定删除该组织分类及全部下级分类？',
                width: '240',
                onOk: () => {
                    this.listLoading = true;
                    reqDelDepartment({id: deportmentId}).then(res => {
                        this.listLoading = false;
                        if (res.data.code === 1) {
                            this.$Message.success("删除成功");
                            // 删除数据成功同时刷新grid
                            this.getTreeList();
                        }
                    }).catch(() => {
                        this.listLoading = false;
                        this.$Message.error("删除失败");
                    });
                }
            })
        },
        // 树渲染逻辑
        renderContent (h, { root, node, data }) {
            return h('span', {
                class: 'common-wrap',
                on:{
                    click:() => {
                        // 点击Tree节点触发
                        if (!data.editState) {
                            this.handleClickTreeNode(root, data, node)
                        }
                    }
                }
            }, [
                h('span', [
                    // 文件前面的文件夹图标
                    h('Icon', {
                        props: {
                            type: `${data.children === undefined || data.children.length === 0 ? 'md-document' :'ios-folder'}`
                        },
                        style: {
                            marginRight: '8px',
                        }
                    }),
                    h(`${ data.editState ? '' : 'span'}`, data.title),
                    h(`${ data.editState ? 'input' : ''}`,
                        {
                            class: 'edit-input',
                            attrs:{
                                value:`${ data.editState ? data.title : ''}`,
                                autofocus: 'true'
                            },
                            style: {
                                // cursor: 'auto' ,
                                // borderRadius: '3px',
                                // border: '1px solid #e5e5e5',
                                // color: '#515a6e'
                            },
                            on:{
                                change: (event) => {
                                    this.inputContent = event.target.value
                                }
                            }
                        }
                    )
                ]),
                // 增删改按钮部分
                h(`${ data.editState ? '' : 'span'}`,
                    {
                        class: 'btnNone'
                    },
                    [
                        // 操作按钮部分
                        // 编辑按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-color-filter'
                            },
                            on: {
                                click: () => {
                                    this.editTree(data) }
                            }
                        }),
                        // 添加按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-add'
                            },
                            on: {
                                click: () => { this.append(data) }
                            }
                        }),
                        // 删除按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-remove'
                            },
                            on: {
                                click: () => { this.remove(root, node, data) }
                            }
                        })
                    ]
                ),
                // 确认/取消修改部分
                h(`${ data.editState ? 'span' : ''}`,
                    {
                        style: {
                            marginLeft: '.5rem'
                        }
                    },
                    [
                        // 确认按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-checkmark'
                            },
                            style: {
                                border: 0,
                                background: 'rgba(0,0,0,0)',
                                fontSize: '16px',
                                outline: 'none'
                            },
                            on: {
                                click: (event) => {
                                    this.confirmTheChange(data)
                                }
                            }
                        }),
                        // 取消按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-close'
                            },
                            style: {
                                border: '0',
                                background: 'rgba(0,0,0,0)',
                                fontSize: '16px',
                                outline: 'none'
                            },
                            on: {
                                click: () => { this.cancelChange(data) }
                            }
                        })
                    ]
                )
            ])
        },
        /**
         * @description: 获取树列表
         * @param {*}
         * @return {*}
         */
        getTreeList() {
            reqGetDepartmentTreeOpts().then(res => {
                this.treeList = JSON.parse(JSON.stringify(res.data.data));
                this.treeSelectOpts = JSON.parse(JSON.stringify(res.data.data));
            }).catch(()=>{
                this.treeList = [];
                this.treeSelectOpts = [];
            });
        },
        getChildListById() {
            if (!this.selectedId) {
                this.deportmentList = [];
            } else {
                let params = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    id: this.selectedId
                }
                reqGetChildListById(params).then(res => {
                    this.deportmentList = res.data.data.records;
                    this.pageNum = res.data.data.pageNum;
                    this.total = res.data.data.total;
                    this.pages = Math.ceil(res.data.data.total / this.pageSize);
                }).catch(()=>{
                    this.deportmentList = [];
                });
            }
        },
        // 控制Tree当前状态函数
        setStates(data){
            let editState=data.editState
            if (editState) {
                this.$set(data, 'editState', false)
            } else {
                this.$set(data, 'editState', true)
            }
        },
        // Tree修改按钮
        editTree(data){
            this.deportment = data;
            this.editShow = true;
        },
        // 添加按钮
        append (data) {
            this.parentId = data.id;
            this.addShow = true;
        },
        // 删除按钮
        remove (root, node, data) {
            this.delDelDepartment(data.id);
        },
        // 确认修改树节点
        confirmTheChange(data) {
            if (!this.inputContent) {
                this.$Notice.warning({
                    title: '当前输入有误',
                })
            } else {
                if(this.oldName !== this.inputContent){
                    this.$Modal.confirm({
                        title: '提示',
                        content: `您确定将  “${this.oldName}”  重命名为 “ ${this.inputContent} ” 吗？`,
                        onOk: () => {
                            data.title=this.inputContent
                            this.$Message.info('修改成功')
                        },
                        onCancel: () => {
                            this.$Message.info('取消')
                        }
                    })
                    this.setStates(data)
                } else{
                    this.setStates(data)
                }
            }
        },
        // 取消修改树节点
        cancelChange(data) {
            this.$Notice.info({
                title: '取消修改',
            })
            this.setStates(data)
        },
        // 点击Tree节点触发
        handleClickTreeNode(root, data, node) {
            this.selectedId = data.id;
            this.pageNum = 1;
            this.isSearching = 0;
            this.keywords = '';
            this.getChildListById();
        },
        // js 递归遍历查找对象数组的某一个属性
        filterTableMater(code, arr) {
            for (const item of arr) {
                if (item.id === code) {
                    item.selected = true
                }
                if (item.children && item.children.length) {
                    this.filterTableMater(code, item.children)
                }
            }
        }
    },
    mounted() {
        this.getTreeList();
        this.doSearching();
        let permissionId = this.$route.query.permissionId
        permission.getPermissionButton(permissionId, this.permissionButton)
    }
}
</script>
